import axios from 'axios';
import nexo from './nexoClient';
import { getSessionToken } from '@tiendanube/nexo/helpers';

const axiosIntance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosIntance.interceptors.request.use(async (request) => {
  const token = await getSessionToken(nexo);
  const bearerToken = `Bearer ${token}`;
  request.headers.Authorization = bearerToken;
  return request;
});

export default axiosIntance;
