import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  List,
  useToast,
  Input,
  Text,
  Label,
} from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';

import axiosInstance from '../../axios';

const SetupPage: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(true);
  const [isVinculate, setIsVinculate] = useState(false);
  const [showMessageVinculateAccount, setShowMessageVinculateAccount] =
    useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    usuario_api: '',
    access_key: '',
  });
  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    checkVinculateAccount();
  }, []);

  const handleGoToDashboard = async () => {
    push('/');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const handleChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const getDashboard = async () => {
    try {
      const r = await axiosInstance.get('/dashboard');
      console.log(r);
    } catch (e) {
      console.error(e);
    }
    return;
  };
  getDashboard();

  const checkVinculateAccount = async () => {
    console.log('checkeand vinculacion de cuenta...');
    try {
      const response = await axiosInstance.get('/check_status_account');
      console.log(response);
      if (response.data.status == true) {
        console.log('CUENTA YA VINCULADA');
        setIsVerifing(false);
        setIsVinculate(true);
      }
    } catch (e) {
      console.error(e);
      setIsVerifing(false);
      setIsVinculate(false);
    }
    return;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const handleVinculateAccount = async () => {
    setLoading(true);
    const isFormCorrect = validateEmail(user.usuario_api);
    if (isFormCorrect) {
      setShowMessageVinculateAccount(true);
      try {
        const response = await axiosInstance.post('/register', {
          usuario_api: user.usuario_api,
          siigo_token: user.access_key,
        });
        if (response.data.status == true) {
          addToast({
            id: 'login-toast',
            type: 'success',
            text: 'Vinculación de cuenta exitosa! 😀',
            duration: 4000,
          });
          setIsVerifing(false);
          setIsVinculate(true);
          setShowMessageVinculateAccount(false);
        } else {
          setShowMessageVinculateAccount(false);
          addToast({
            id: 'login-toast',
            type: 'danger',
            text: 'Error Vinculando cuenta. Verifique sus credenciales.',
            duration: 4000,
          });
        }
      } catch (error) {
        console.log(error);
        setError(true);
        setShowMessageVinculateAccount(false);
      }
    } else {
      addToast({
        id: 'email-message-toast',
        type: 'danger',
        text: 'Verifique su email',
        duration: 4000,
      });
    }
    setLoading(false);
    return;
  };

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  return (
    <Page maxWidth="1000px" marginTop="18">
      {!isVerifing && isVinculate ? (
        <>
          <Page.Header
            alignItems="center"
            title="Tu cuenta ya está vinculada y activa"
          />
          <Box display="flex" gap="4" justifyContent="center">
            <Button appearance="primary" onClick={handleGoToConfig}>
              <Icon source={<CogIcon />} color="currentColor" />
              Ir a configuración
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Page.Header alignItems="center" title="Conecta tu cuenta Siigo" />
          <Page.Body>
            <Layout columns="1">
              <Layout.Section>
                <Card>
                  <Card.Header title="¡Solo te queda 1 paso! Para encontrar tus nuevas credenciales ingresa a tu cuenta Siigo" />
                  <Card.Body>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="4"
                      padding="4"
                    >
                      <List as="ul">
                        <List.Item>
                          Ve a <strong>Alianzas {'>'} Mi credencial API</strong>
                          <a
                            href="https://siigonube.siigo.com/#/api-credentials"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <strong>&nbsp;&nbsp;&nbsp;(ver aquí)</strong>
                          </a>
                        </List.Item>
                        <List.Item>
                          La plataforma te solicitará algunos datos y luego se
                          generarán tus credenciales.
                        </List.Item>
                        <List.Item>
                          Copia y carga en el formulario tu{' '}
                          <strong>Usuario API </strong> y{' '}
                          <strong>Access KEY</strong>
                        </List.Item>
                      </List>
                      <hr />
                      <FormField label="Usuario API *">
                        <Input
                          name="usuario_api"
                          type="text"
                          placeholder="Ejemplo: ejemplo@siigo.com"
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      </FormField>
                      <FormField label="Access KEY *">
                        <Input.Password
                          name="access_key"
                          placeholder="Ejemplo: Xxx111yYY222"
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      </FormField>
                    </Box>
                  </Card.Body>
                </Card>
                <Box display="flex" justifyContent="flex-end" gap="4">
                  <Button onClick={handleGoToDashboard}>CANCELAR</Button>
                  {loading ? (
                    <Button appearance="primary">
                      <Spinner size="small" color="neutral-background" />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleVinculateAccount}
                      type="submit"
                      appearance="primary"
                    >
                      VINCULAR
                    </Button>
                  )}
                </Box>
                <Box
                  display={showMessageVinculateAccount ? 'flex' : 'none'}
                  justifyContent="center"
                  gap="10"
                  marginTop="2"
                  marginBottom="6"
                >
                  <Text as="p" fontSize="highlight">
                    <p>
                      Vinculando cuenta, por favor aguarde unos instantes...
                    </p>
                  </Text>
                </Box>
                <Box display={'flex'}>
                  <Text id="setup-register-link" as="p" fontSize="base">
                    Si no tienes cuenta en Siigo, registrate haciendo click{' '}
                    <a
                      href="https://landing.siigo.com/alianzas/tiendanube/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <strong>aquí</strong>.
                    </a>
                  </Text>
                </Box>
              </Layout.Section>
            </Layout>
          </Page.Body>
        </>
      )}
    </Page>
  );
};

export default SetupPage;
