import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Icon,
  Button,
  Box,
  Thumbnail,
  IconButton,
  Card,
  Text,
  Link,
} from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { CogIcon, ExternalLinkIcon, RocketIcon } from '@nimbus-ds/icons';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useWindowWidth } from '../../hooks';

// import axiosInstance from '../../axios';

function PageDashboard() {
  const { push } = useHistory();
  const windowWidth = useWindowWidth();

  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');

  const handleGoToSetup = async () => {
    push('/setup');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const handleGoToLogs = async () => {
    push('/logs');
  };

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        onClick={handleGoToConfig}
        source={<CogIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Button onClick={handleGoToSetup}>
          <Icon source={<RocketIcon />} color="currentColor" />
          Setup
        </Button>
        <Button appearance="primary" onClick={handleGoToConfig}>
          <Icon source={<CogIcon />} color="currentColor" />
          Configuración
        </Button>
        <Button appearance="primary" onClick={handleGoToLogs}>
          <Icon source={<CogIcon />} color="currentColor" />
          Registros
        </Button>
      </Box>
    );

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <Page maxWidth="1000px">
      <Page.Header title="" buttonStack={actions} />
      <Page.Body>
        <Thumbnail
          src="logo_siigo.png"
          alt="my-image"
          height="120px"
          width="200px"
        />
        <Layout columns="1">
          <Layout.Section>
            <Card>
              <Card.Header title="Bienvenido/a a Siigo APP" />
              <Card.Body>
                <Text as="p" fontSize="highlight">
                  Con esta aplicación podrás sincronizar tus clientes,
                  productos, stock, precios y crear facturas electrónicas.
                  ahora, no podrías manejar stock de múltiples centros de
                  distribución o bodegas en Siigo.
                </Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Text as="span" fontSize="highlight">
                  <Box marginTop="2" marginBottom="2">
                    <p>
                      Antes de empezar, te solicitamos leas los requisitos
                      generales y veas el instructivo que te compartimos acá
                      abajo. Te recomendamos leer el tutorial de principio a
                      fin, dado que algunas acciones no podrán ser reversadas.
                      Ante cualquier duda, comunícate a
                      <strong> siigoapp@leren.com.ar </strong> para asesorarte y
                      llevar a cabo la configuración de forma exitosa.
                    </p>
                  </Box>
                  <Box marginTop="4" marginBottom="2">
                    <p>Antes de ir a “Configuración” asegúrate que:</p>
                  </Box>
                  <Box marginLeft="12" gap="4" marginBottom="4">
                    <ul>
                      <li>La moneda de tu tienda debe ser pesos colombianos</li>
                      <li>
                        Los precios de tu tienda deben tener el IVA incluido
                      </li>
                      <li>
                        En en check out se debe solicitar de manera obligatoria
                        el documento de identidad
                      </li>
                      <li>
                        Todos los productos de tu tienda deben tener su{' '}
                        <strong>
                          SKU relacionado con el código de producto en Siigo{' '}
                        </strong>
                        , debes asegurarte que sean iguales en las dos
                        plataformas, esto para asegurar el correcto
                        funcionamiento de la integración.
                      </li>
                    </ul>
                  </Box>
                  <p>Además , tener en cuenta que: </p>
                  <p>
                    Los productos se crearán sin fotos y como{' '}
                    <strong>productos independientes</strong> (sin variantes).
                  </p>
                </Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  gap="4"
                  marginTop="6"
                >
                  <Button appearance="primary">
                    <Link
                      as="a"
                      href="https://scribehow.com/page/Siigo_APP__1nUKzrNARnyp49IRtLHUqQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      VER INSTRUCTIVO
                    </Link>
                  </Button>
                </Box>
              </Card.Body>
              <Card.Footer>
                <Link
                  as="a"
                  href="https://www.siigo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Conocé más en siigo.com
                  <Icon color="currentColor" source={<ExternalLinkIcon />} />
                </Link>
              </Card.Footer>
            </Card>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
}

export default PageDashboard;
