import {
  CogIcon,
  HomeIcon,
  RocketIcon /* , PictureIcon, TagIcon */,
} from '@nimbus-ds/icons';

export const appRoutes = [
  {
    title: 'Dashboard',
    name: 'dashboard',
    slug: '/',
    icon: HomeIcon,
  },
  {
    title: 'Setup',
    name: 'setup',
    slug: '/setup',
    icon: RocketIcon,
  },
  {
    title: 'Config',
    name: 'config',
    slug: '/config',
    icon: CogIcon,
  },
  // {
  //   title: 'Galería de ejemplos',
  //   name: 'galeria-de-ejemplos',
  //   slug: '/examples',
  //   icon: PictureIcon,
  // },
  // {
  //   title: 'Productos de mi tienda',
  //   name: 'productos-de-mi-tienda',
  //   slug: '/products',
  //   icon: TagIcon,
  // },
];
